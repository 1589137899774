import { Controller } from "@hotwired/stimulus";
import planer from "planer";

export default class extends Controller {
  static targets = ["content"];

  connect() {
    this.parseEmail();
  }

  parseEmail() {
    // Strip out quoted replies.
    const msgBody = this.contentTarget.innerHTML;
    const actualMessage = planer.extractFromHtml(msgBody, window.document).trim();
    this.contentTarget.innerHTML = actualMessage;
  }
}
