import { BridgeComponent, BridgeElement } from "@hotwired/hotwire-native-bridge";

export default class extends BridgeComponent {
  static component = "external-link";

  onClick(event) {
    event.preventDefault();

    const path = this.bridgeElement.bridgeAttribute("path") || this.bridgeElement.attribute("href");
    const query = this.bridgeElement.bridgeAttribute("query");

    this.send("click", { path, query }, () => {});
  }
}
