import { BridgeComponent } from "@hotwired/hotwire-native-bridge";
import { destroy } from "@rails/request.js";

export default class extends BridgeComponent {
  static component = "enable-notifications";
  static targets = ["description", "token", "platform", "checkbox"];
  static values = {
    existingTokens: Object,
  };

  connect() {
    this.send("connect", { existingTokens: Object.values(this.existingTokensValue) }, (message) => {
      const { data } = message;
      if (data.registered === true) {
        this.enableToggle();
      } else {
        this.disableToggle();
      }
    });
  }

  enableToggle() {
    this.checkboxTarget.checked = true;
  }

  disableToggle() {
    this.checkboxTarget.checked = false;
  }

  click(event) {
    event.preventDefault();

    if (this.checkboxTarget.checked) {
      this.enableNotifications();
    } else {
      this.disableNotifications();
    }
  }

  enableNotifications() {
    this.send("click", {}, (message) => {
      const { data } = message;
      this.tokenTarget.value = data.token;
      this.descriptionTarget.value = data.description;
      this.platformTarget.value = data.platform;
      this.element.requestSubmit();
    });
  }

  disableNotifications() {
    // TODO: Maybe this would be better as a turbo-streamed button_to form?
    this.send("click", {}, (message) => {
      const { data } = message;
      const selectedToken = Object.keys(this.existingTokensValue).find(
        (id) => this.existingTokensValue[id] === data.token,
      );
      const action = this.element.getAttribute("action");
      let url = action;
      if (!action.endsWith(selectedToken)) {
        url = `${this.element.getAttribute("action")}/${selectedToken}`;
      }
      destroy(url, { responseKind: "turbo-stream" });
    });
  }
}
